
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import moment from 'moment';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { ElNotification } from 'element-plus';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
const XLSX = require('xlsx');
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Status Monitoring Report',
  components: {
    Form,
    Datatable,
    SearchBar,
    ElNotification,
    Swal,
    XLSX,
  },
  data() {
    return {
      moment: '' as any,
      api_url: '',
      load: false,
      loading: false,
      tranches_info: [] as any,
      tranche_id: '' as any,
      ethnic_id: '' as any,
      total_institute: 0 as any,
      active_institute: 0 as any,
      inactive_institute: 0 as any,
      suspend_institute: 0 as any,
      total_trainer: 0 as any,
      active_trainer: 0 as any,
      inactive_trainer: 0 as any,
      suspend_trainer: 0 as any,
      total_assesor: 0 as any,
      active_assesor: 0 as any,
      inactive_assesor: 0 as any,
      suspend_assesor: 0 as any,
      tableHeader: [
        {
          name: 'SL No',
          key: 'sl',
          sortable: false,
        },
        {
          name: 'IA / Govt. Org',
          key: 'association',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Institute Name',
          key: 'institute_name',
          sortable: true,
          width: '300px',
        },

        {
          name: 'Short Name',
          key: 'short_name',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Present Address',
          key: 'present_address',
          sortable: true,
          width: '300px',
        },
        {
          name: 'Present City',
          key: 'present_city',
          sortable: true,
        },
        {
          name: 'Upozila',
          key: 'upozila',
          sortable: true,
        },
        {
          name: 'District',
          key: 'present_district',
          sortable: true,
        },
        {
          name: 'Division',
          key: 'division',
          sortable: true,
        },
        // {
        //   name: 'Trainning Capacity',
        //   key: 'trainning_capacity',
        //   sortable: true,
        // },
        {
          name: 'Phone',
          key: 'phone',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Web URL',
          key: 'web_url',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
        },
        {
          name: 'Course Name',
          key: 'course',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Batch No',
          key: 'batch_no',
          sortable: true,
        },
        {
          name: 'Training Start Date',
          key: 'start_date',
          sortable: true,
        },
        {
          name: 'Training End Date',
          key: 'end_date',
          sortable: true,
        },
      ],
      trainerHeader: [
        {
          name: 'SL',
          key: 'sl',
          sortable: false,
        },
        {
          name: 'Trainer Name',
          key: 'trainer_name',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          name: 'Is Guest Trainer?',
          key: 'is_guest_trainer',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Religion',
          key: 'religion',
          sortable: true,
        },
        {
          name: 'Mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Associations',
          key: 'associations',
          sortable: true,
        },
        {
          name: 'Institutes',
          key: 'institute',
          sortable: true,
        },
        {
          name: 'Tranches',
          key: 'tranche',
          sortable: true,
        },
        {
          name: 'Courses',
          key: 'courses',
          sortable: true,
          width: '250px',
        },
        {
          name: 'Last Batch Number',
          key: 'last_batch_number',
          sortable: true,
          width: '150px',
        },
        {
          name: 'Last Batch Start Date',
          key: 'last_batch_start_date',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Last Batch End Date',
          key: 'last_batch_end_date',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Years of Experience',
          key: 'years_experience',
          sortable: true,
          width: '170px',
        },
      ],
      assesorHeader: [
        {
          name: 'SL No',
          key: 'sl',
          sortable: false,
        },
        {
          name: 'Assesor Name',
          key: 'assesor_name',
          sortable: true,
        },
        {
          name: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          name: 'Gender',
          key: 'gender',
          sortable: true,
        },
        {
          name: 'Religion',
          key: 'religion',
          sortable: true,
        },
        {
          name: 'Mobile',
          key: 'mobile',
          sortable: true,
        },
        {
          name: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          name: 'Associations',
          key: 'associations',
          sortable: true,
        },
        {
          name: 'Institutes',
          key: 'institute',
          sortable: true,
        },
        {
          name: 'Tranches',
          key: 'tranche',
          sortable: true,
        },
        {
          name: 'Courses',
          key: 'courses',
          sortable: true,
          width: '250px',
        },
        {
          name: 'Last Batch Number',
          key: 'last_batch_number',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Last Batch Start Date',
          key: 'last_batch_start_date',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Last Batch End Date',
          key: 'last_batch_end_date',
          sortable: true,
          width: '170px',
        },
        {
          name: 'Years of Experience',
          key: 'years_experience',
          sortable: true,
          width: '170px',
        },
      ],
      status: '',
      instituteData: [] as any,
      trainerData: [] as any,
      batchStatus_trainer: '' as any,
      assesorData: [] as any,
      componentKey: 0,
      assesorKey: 0,
      trainerKey: 0,
      showInstitute: false,
      showTrainer: false,
      showAssesor: false,
      yearofexp: '' as any,
    };
  },
  async created() {
    await this.getStatusMonitor();
    this.moment = moment;
    // await this.districtTrainee();
  },
  methods: {
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });
      let formData = new FormData();

      formData.set('status', this.status);
      await ApiService.post('report/trainning-institute-statuspdf', formData)
        .then((response) => {
          ElNotification.closeAll();
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          ElNotification.closeAll();
          console.log(response);
        });
    },
    async printPdf() {
      let formData = new FormData();
      formData.set('status', this.status);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/trainning-institute-statusprint`,
          formData
        )
        .then((response) => {

          document.write(response.data);
          // window.print();
          // location.reload();
          //open the new window and write your HTML to it
        });
    },
    async exportTrainee() {
      const ws1 = XLSX.utils.table_to_sheet(
        document.querySelector('.instituteData')
      );

      // Create a new workbook and add the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1');

      // Auto-fit column widths for all columns in the first sheet
      const wsName = 'Sheet1';
      const ws = wb.Sheets[wsName];
      const range = XLSX.utils.decode_range(ws['!ref']);

      for (let C = range.s.c; C <= range.e.c; ++C) {
        let maxCellLength = 0;
        let maxHeaderLength = 0;
        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
          const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
          if (cell && cell.v) {
            const cellLength = cell.v.toString().length;
            if (cellLength > maxCellLength) {
              maxCellLength = cellLength;
            }
          }
        }
        const headerCell = ws[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
        if (headerCell && headerCell.v) {
          maxHeaderLength = headerCell.v.toString().length;
        }
        const columnWidth = Math.max(
          (maxCellLength + 2) * 1.2,
          (maxHeaderLength + 2) * 1.2
        );
        ws[XLSX.utils.encode_col(C) + '1'].s = {
          // update the column header cell style
          font: { bold: true, color: { rgb: '000000' } },
          alignment: { horizontal: 'center' },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: { rgb: 'c6efce' },
          },
        };
        ws['!cols'] = ws['!cols'] || [];
        ws['!cols'][C] = { width: columnWidth };
      }

      // Save the file
      const date = new Date();
      const fileName = `Institute_Status_${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}.xlsx`;
      XLSX.writeFile(wb, fileName);
    },
    async downloadPdfTwo() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });

      // Encode compressedData as Base64
      let formData = new FormData();

      formData.set('status', this.status);

      await ApiService.post('report/trainer-statuspdf', formData)
        .then((response) => {
          ElNotification.closeAll();
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          ElNotification.closeAll();
          console.log(response);
        });
    },
    async printPdfTwo() {
      let formData = new FormData();
      formData.set('status', this.status);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/trainer-statusprint`,
          formData
        )
        .then((response) => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it
        });
    },
    async exportTraineeTwo() {
      const ws1 = XLSX.utils.table_to_sheet(
        document.querySelector('.trainerData')
      );

      // Create a new workbook and add the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1');

      // Auto-fit column widths for all columns in the first sheet
      const wsName = 'Sheet1';
      const ws = wb.Sheets[wsName];
      const range = XLSX.utils.decode_range(ws['!ref']);

      for (let C = range.s.c; C <= range.e.c; ++C) {
        let maxCellLength = 0;
        let maxHeaderLength = 0;
        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
          const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
          if (cell && cell.v) {
            const cellLength = cell.v.toString().length;
            if (cellLength > maxCellLength) {
              maxCellLength = cellLength;
            }
          }
        }
        const headerCell = ws[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
        if (headerCell && headerCell.v) {
          maxHeaderLength = headerCell.v.toString().length;
        }
        const columnWidth = Math.max(
          (maxCellLength + 2) * 1.2,
          (maxHeaderLength + 2) * 1.2
        );
        ws[XLSX.utils.encode_col(C) + '1'].s = {
          // update the column header cell style
          font: { bold: true, color: { rgb: '000000' } },
          alignment: { horizontal: 'center' },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: { rgb: 'c6efce' },
          },
        };
        ws['!cols'] = ws['!cols'] || [];
        ws['!cols'][C] = { width: columnWidth };
      }

      // Save the file
      const date = new Date();
      const fileName = `Trainer_Status_${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}.xlsx`;
      XLSX.writeFile(wb, fileName);
    },

    async downloadPdfThree() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
        duration: 0,
      });

      let formData = new FormData();
      formData.set('status', this.status);

      await ApiService.post('report/assesor-statuspdf', formData)
        .then((response) => {
          ElNotification.closeAll();
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          ElNotification.closeAll();
          console.log(response);
        });
    },
    async printPdfThree() {
      let formData = new FormData();
      formData.set('status', this.status);
      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/assesor-statusprint`,
          formData
        )
        .then((response) => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it
        });
    },
    async exportTraineeThree() {
      const ws1 = XLSX.utils.table_to_sheet(
        document.querySelector('.assesorData')
      );

      // Create a new workbook and add the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1');

      // Auto-fit column widths for all columns in the first sheet
      const wsName = 'Sheet1';
      const ws = wb.Sheets[wsName];
      const range = XLSX.utils.decode_range(ws['!ref']);

      for (let C = range.s.c; C <= range.e.c; ++C) {
        let maxCellLength = 0;
        let maxHeaderLength = 0;
        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
          const cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
          if (cell && cell.v) {
            const cellLength = cell.v.toString().length;
            if (cellLength > maxCellLength) {
              maxCellLength = cellLength;
            }
          }
        }
        const headerCell = ws[XLSX.utils.encode_cell({ r: range.s.r, c: C })];
        if (headerCell && headerCell.v) {
          maxHeaderLength = headerCell.v.toString().length;
        }
        const columnWidth = Math.max(
          (maxCellLength + 2) * 1.2,
          (maxHeaderLength + 2) * 1.2
        );
        ws[XLSX.utils.encode_col(C) + '1'].s = {
          // update the column header cell style
          font: { bold: true, color: { rgb: '000000' } },
          alignment: { horizontal: 'center' },
          fill: {
            type: 'pattern',
            patternType: 'solid',
            fgColor: { rgb: 'c6efce' },
          },
        };
        ws['!cols'] = ws['!cols'] || [];
        ws['!cols'][C] = { width: columnWidth };
      }

      // Save the file
      const date = new Date();
      const fileName = `Assesor_Status_${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}.xlsx`;
      XLSX.writeFile(wb, fileName);
    },
    async TrainningInstituteStatus(status) {
      this.load = true;
      this.status = status;
      await ApiService.get('report/trainning-institute-status?status=' + status)
        .then((response) => {
          this.instituteData = response.data.data;

          this.componentKey += 1;
          this.showTrainer = false;
          this.showAssesor = false;
          this.showInstitute = true;

          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async TrainerStatus(status) {
      this.status = status;
      this.load = true;
      await ApiService.get('report/trainer-status?status=' + status)
        .then((response) => {
          // this.trainerData = response.data.data.trainerstatus;
          this.trainerData = response.data.data.trainerstatus;
          this.showInstitute = false;
          this.showAssesor = false;
          this.showTrainer = true;

          this.trainerKey += 1;

          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async AssesorStatus(status) {
      this.status = status;
      this.load = true;
      await ApiService.get('report/assesor-status?status=' + status)
        .then((response) => {
          this.assesorData = response.data.data.assesorstatus;

          this.assesorKey += 1;
          this.showInstitute = false;
          this.showTrainer = false;
          this.showAssesor = true;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getStatusMonitor() {
      this.load = true;
      await ApiService.get('report/status-monitor-report')
        .then((response) => {
          (this.total_institute = response.data.data.total_institute),
            (this.active_institute = response.data.data.active_institute),
            (this.inactive_institute = response.data.data.inactive_institute),
            (this.suspend_institute = response.data.data.suspend_institute),
            (this.total_trainer = response.data.data.total_trainer),
            (this.active_trainer = response.data.data.active_trainer),
            (this.inactive_trainer = response.data.data.inactive_trainer),
            (this.suspend_trainer = response.data.data.suspend_trainer),
            (this.total_assesor = response.data.data.total_assesor),
            (this.active_assesor = response.data.data.active_assesor),
            (this.inactive_assesor = response.data.data.inactive_assesor),
            (this.suspend_assesor = response.data.data.suspend_assesor),
            // this.traineeDistrictData = response.data.data.traineeDistrictData;

            (this.componentKey += 1);
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
